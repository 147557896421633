.demo-card--container{
    position: relative;
    border-left: 10px solid #2B93A5;
    border-radius:0  8px 8px 0;
    box-shadow: 0 0 3px #94A2AB;
    margin: 20px auto;
    display: flex;
    padding: 10px 0;
}
.demo-card--container:hover{
    box-shadow: 0 0 5px #707070;
}
.tutor-profilePic--wrapper{
    box-shadow: 0 0 5px #94A2AB;
    width: 100px;
    height: 100px;
    margin: auto;
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;



}
.demo-card--container--info-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
}
.demo-card--container--info-wrapper--left{
    width: 50%;
    color: #828282;
    font-size: 14px;
}
.demo-card--container--info-wrapper--left--tutor-name{
    font-size: 24px;
    color: #2B93A5;
}
.demo-card--container--info-wrapper--left>div{
    padding: 2px 0;
}
.demo-card--container--info-wrapper--right{
    width: 35%;
    max-width: 245px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 20px;
    display: none;
}
.demo-card--container--info--view-wrapper{
    display: flex;
    justify-content: end;
    color:#2B93A5;
    text-decoration: underline;
    cursor: pointer;
}

.demo-card--container--info--rating{
    color: #828282;
    font-size: 14px;
}
.demo-card--container--info--invite-for-demo--wrapper{
    box-shadow: 0 0 3px #707070;
    color: white;
    font-weight: 450;
    background-color: #2B93A5;
    border-radius: 5px;
    padding: 3px 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 120px;
}
.hireTutor-btn{
    padding: 3px 8px;
    width: 100px;
    text-align: center;
    background-color: #ADCE4E;
}
.fireTutor-btn{
    width: 100px;
    text-align: center;
    padding: 3px 8px;
    background-color: #D8B70D;

}
.assigned-tutors--container .demo-card--container--info--invite-for-demo--wrapper{
    padding: 5px 10px;
}
.tutor-card-complete-payment{
    text-decoration: underline;
    display: flex;
    justify-content: end;
    cursor: pointer;
}

.tutor-popup-wrapper{
    /* position: relative; */
    box-sizing: border-box;
    height: 85vh;
    /* overflow: hidden; */
}
.tutor-popup-wrapper--transparent-screen{
    position: absolute;
    background-color: #70707065;
    z-index: 101;
    top: 0px;
    left: 0px;
    width: 99.7vw;
    height: 100vh;

}
.tutor-popup-wrapper--content-screen{
    position: absolute;
    top:62%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 800px;
    height: 500px;
    border-radius: 8px;
    background-color: white;
    z-index: 102;
    
}
.close-tutor-popup-btn{
    /* text-align: end; */
    display: flex;
    justify-content: center;
    width: 50px;
    padding: 3px 5px;
    text-decoration: underline;
    margin-left: auto;
    cursor: pointer;
    /* border-radius: 5px; */
}
.tutor-hire--popup--wrapper{
    text-align: center;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.tutor-hire--popup--wrapper>div{
    height: 60px;
    padding: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.tutor-hire--popup--wrapper input,.tutor-hire--popup--wrapper select{
    border-radius: 5px;
    width: 40%;
    height: 25px;
    padding: 2px 10px;
    border: 2px solid #2B93A5;
    font-size: 16px;
    /* color: #828282; */
}
.popup-btn-wrappers{
    
    padding: 50px 0 20px 0 ;
    display: flex;
    justify-content: center;
}

.popup-btn-wrappers>div{
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 10px;
}
.card-status-dot{
    width: 7px;
    height: 7px;
    box-shadow: 0 0 3px rgb(248, 92, 19);
    margin-left: 3px;
    border-radius: 50%;
    background-color: rgb(248, 92, 19);
}
.demo-card--container-top-bar-wrapper--top-left{
    /* width:65%; */
    display: flex;
}
 
.end-content-desktop{
    display: flex;
    justify-content: end;
}

@media (max-width: 768px) {
    .end-content-desktop{
        display: flex;
        justify-content: space-around;
    }
    .demo-card--container{
        display: block;
        border: 0;
        border-radius: 5px;
    }
    .demo-card--container--info-wrapper--left,.demo-card--container--info-wrapper--right{
        width: auto;
    }
    .demo-card--container--info-wrapper--right{
        max-width: 50%;
        padding: 0px;
        display: block;
    }
    .demo-card--container--info-wrapper{
        padding: 5px;
    }
    .demo-card--container--info--view-wrapper{
        display: none;
    }
    .tutor-profilePic--wrapper{
        width: 60px;
        height: 60px;
        margin: 0 5px;
    }
    .demo-card--container-top-bar-wrapper{
        display: flex;
        justify-content: space-between;
    }
    .demo-card--container--info--ratings--wrapper{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 100%;
    }
    .demo-card--container--info-wrapper--left--tutor-name{
        font-size: 16px;
    }
    .demo-card--container{
        margin: 8px auto;
    }
    .demo-card--container--info--invite-for-demo--wrapper {
        font-size: 13px;
        max-width: 100px;
    }
    .demo-card--container--info-wrapper {
        width: auto;
    }
    .demo-card--container-top-bar-wrapper--top-left{
        width:65%;
        display: flex;
    }
    .demo-card--container-top-bar-wrapper--top-right{
        width:35%;
        font-size: 12px;
        color: rgb(248, 92, 19);
        font-weight: 500;
        display: flex;
        justify-content: end;
        padding-right: 8px;
        align-items: center;
    }
    
}
