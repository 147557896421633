.address-component input,.personal-info--personal-content-2 input{

    width: 95%;
    padding: 3px 10px;
    margin: 3px 0;
    border: 1px solid #2B93A5;
    border-radius: 5px;
}
.editInput input{
    color: #353a3b;
    border-radius: 5px;
}
.address-component select,.personal-info--personal-content-2 select{
    border: 0;
    color: black;
    width: 150px;
}

.showRequest-Save-wrapper button{
  padding: 8px 10px;
  color: rgba(217, 217, 217, 1);
  border: 0;
  border-radius: 5px;
  background-color: #2B93A5;
  width: 100px;
  box-shadow: 0px 0px 3px #2B93A5;
  cursor: pointer;
  text-align: center;
}
.showRequest-cancel-wrapper button{
    padding: 8px 10px;
    color: white;
    border: 0;
    border-radius: 5px;
    background-color: #6a7c80;
    width: 100px;
    box-shadow: 0px 0px 3px #2B93A5;
    cursor: pointer;
    text-align: center;

}
.showRequest-Save-cancel-wrapper{
    display: flex;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
}
.showRequest-Save-cancel-wrapper >div{
}

.city-dropdown .MuiOutlinedInput-input.MuiSelect-select,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  border: 1px solid #2b93a5 !important;
  padding: 3px 10px !important;
}


@media (max-width: 768px) {
    .showRequest-Save-cancel-wrapper{
        width: 70%;
        margin-bottom: 20px;
    }
    .address-component input, .personal-info--personal-content-2 input{
        width: 90%;
    }
}



