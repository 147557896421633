.pathway-requestBtn--wrapper{
    margin: 20px 0;;
    display: flex;
    justify-content: space-between;
}
.pathway--wrapper{
    display: flex;
    align-items: center;

}
.pathway--wrapper > div{
    padding: 3px 10px;
}
.pathway--btn{
    border: 1px solid #D9D9D9;
    font-weight: 400;
    color: black;
    border-radius: 4px;
}
.pathway--wrapper .pathway--btn:last-child{
    border: 1px solid transparent;
    font-weight: 400;
    color: black;
    border-radius: 4px;

}

.formtype-select-options-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px;
    margin-top: 30px;
    width: 60%;
}
.formtype-select-option-1,.formtype-select-option-2,.formtype-select-option-3{
    border: 1px solid transparent;
    box-shadow: 0 0 3px rgba(141, 141, 141, 1);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100px;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
}
.formtype-select-option-1 img,.formtype-select-option-2 img,.formtype-select-option-3 img{
    width: 50px;
}
.formtype-select-option-1 span,.formtype-select-option-2 span,.formtype-select-option-3 span{
    width: 60%;
}
.formtype-select-option-3{
    background-color: rgba(241, 143, 31, 0.692);
}
.formtype-select-option-1{
    background-color: rgba(255, 231, 229, 1);
}
.formtype-select-option-1:hover{
    background-color: rgb(252, 204, 199);
}
.formtype-select-option-2{
  background-color: rgba(187, 227, 255, 1);
}
.formtype-select-option-2:hover{
  background-color: rgb(155, 212, 253);
}


.deal-heading--wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.status--wrapper{
    display: flex;
    align-items: center;

}
.requestId--wrapper{
    display: flex;
    
}
.deal-heading--subheading{
    font-weight: 700;
    font-size: 18px;
    padding: 4px 5px 4px 0;

}
.deal-heading--value{
    color: #2B93A5;
}
.deal-info--container{
    border: 1px solid #94A2AB;
    box-shadow: 0 0 3px #94A2AB;
    margin-top: 10px;
    border-radius: 20px;
}
.deal-info--container--title-wrapper{
    display: flex;
    padding: 10px 0;
}
.deal-info--container--title{
    width: 85%;
    margin-left: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    
}
.deal-info--container--editOption{
    color: #94A2AB;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}
.showRequest--container{
    width: 90%;
    margin: 25px auto;

}
.showRequest--title-wrapper{
    display: flex;
    justify-content: space-between;
}
.showRequest--title{
    color: #94A2AB;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.showRequest--Edit-btn{
    color: #94A2AB;
    width: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

}




.dealFlow-icons--wrapper{
    height: 85px;
    display: flex;
    justify-content: space-around;
}
.deal-mileStone--wrapper{
    height: 165px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.deal-mileStone--icon--wrapper{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.deal-mileStone--status--wrapper{
    z-index: 10;
    border: 3px solid #2B93A5;
    border-radius: 50%;
    box-shadow: 0 0 4px #2B93A5;
    width: 31px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.deal-mileStone--icon{
}
.deal-mileStone--status{
}
.deal-mileStone--title{
    border: 1px solid #94A2AB;
    box-shadow: 0 0 3px #94A2AB;
    border-radius: 5px;
    margin: 12px 0px 0px 0px;
    padding: 5px 8px;
    font-weight: 600;
    z-index: 10;
    background-color: #2B93A5;
    color: white;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dealFlow-lines-wrapper{
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.dealFlow-solid-line{
    border: 2px solid #2B93A5;
    border-radius: 5px;
    width: 90%;
    z-index: 0;
    
}
.dealFlow-dotted-line{
    width: 75%;
    z-index: 0;
    border: 1px dotted #2B93A5;

}

.end-of-dealFlow-icons-line{
    border: 1px solid #94A2AB;
    margin: 50px auto 20px auto;
    width: 90%;
    
}



.pending--wrapper{
}
.payment-pending--wrapper{
    
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 275px;

}
.payment-pending--wrapper{
    padding: 5px 0;
}
.payment-pending--title{
    font-size: large;
    font-weight: 700;
    color: red;
}
.payment-completed--title{
    font-size: large;
    font-weight: 700;
    color: rgba(5, 163, 21, 1);
    
}
.payment-pending--description{
    font-size: large;
    font-weight: 700;
    
}
.payment-pending--paynow-btn{
    width: 150px;
}
.payment-pending--payment-amount{
    font-size: large;
    font-weight: 500;
}
.payment-completed--payment-amount{
    font-size: medium;
    font-weight: 400;
    padding: 5px 0 8px 0;
}

.pending--process-wrapper{
    padding: 10px 20px;
}
.pending--process--steps--wrapper{
    padding: 5px 20px;
    font-size: medium;
}
.pending--payment--TC{
    padding: 15px 20px;
    font-size: small;
    text-align: center;

}


.dealFlow-footer-btn--wrapper{
    display: flex;
    justify-content: center;
}
.dealFlow-footer-btn--wrapper div{
    width: 120px;
    text-align: center;
    margin: 50px 20px 150px 0;

}

.showRequest-inputs-wrapper{
    width: 98%;
    margin:0 auto 35px auto;
}

.blue-dot-inside-input{
    border: 1px solid #2B93A5;
    background-color: #2B93A5;
    box-shadow: 0 0 3px #2B93A5;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.input-icon {
position: relative;
}

.input-icon .blue-dot-inside-input {
position: absolute;
top: 50%;
left: 10px;
transform: translateY(-50%);
}


.small-inputs--wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
}
.small-input--wrapper{
    width: 49%;
    /* height: 58px; */
    margin:10px 0 ;
    font-size: 15px;
    /* color: rgba(24, 24, 24, 0.49); */
    
}
.large-input--wrapper{
    width: 100%;
    height: 58px;
    margin:10px 0 ;
    font-size: 14px;
    color: rgba(24, 24, 24, 0.49);

}
.small-input--wrapper input,.small-input--wrapper select,.large-input--wrapper input{
    width: 90%;
    height: 25px;
    padding: 3px 0 3px 10%;
    border: 1px solid rgba(148, 162, 171, 1);
    border-radius: 5px;
    color: rgba(24, 24, 24, 0.49);
    display: flex;
    align-items: center;
    
}
.small-input--wrapper select{
    height: 37px;
    width: 100%;
    font-size: 14px;
    color: black;
    margin-top: 5px;
    -webkit-appearance: none;
}
.large-input--wrapper input{
    -webkit-appearance: none;
    margin-top: 5px;
    width: 95%;
}

.showRequest-Save-wrapper{
    width: 80px;
    margin:20px auto 8px auto;

}


.demo-tutors--container{
    min-height: 220px;
    width: 90%;
    margin: 10px auto 20px auto;
}
.assigned-tutors--container{
    width: 90%;
    margin: 50px auto 40px auto;
}
.demo-tutors--container--nav-wrapper{
    display: flex;
    width: 50%;
    margin: 0 auto;
    justify-content: space-evenly;

}
.tutors--container--nav-wrapper{
    display: flex;
    width: 30%;
    margin: 0 auto;
    justify-content: space-evenly;

}
.demo-tutors--container--nav-wrapper >div,.tutors--container--nav-wrapper>div{
    border: 1px solid #94A2AB;
    box-shadow: 0 0 3px #94A2AB;
    width: 100px;
    text-align: center;
    padding: 3px 5px;
    border-radius: 3px;
    font-weight: 700;
    cursor: pointer;
}
.active-btn{
    color: white;
    background-color: #2B93A5;
    cursor: pointer;
}
.unactive-btn{
    cursor: pointer;
    color: black;
    background-color: white;
}

.demo-tutors--cards--wrapper{
    max-height: 450px;
    overflow: scroll;
    margin-top: 20px;
    padding: 0 3px;
}


.demo-tutors--cards--wrapper::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }
  
  .demo-tutors--cards--wrapper::-webkit-scrollbar-track {
    background-color: white;
  }
  
  .demo-tutors--cards--wrapper::-webkit-scrollbar-thumb {
    background-color: #c4c8cc;
    border-radius: 5px;
  }
  
  .demo-tutors--cards--wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #979899;
  }


.personal-info--container{
    padding: 40px 20px;
    display: flex;
}
.personal-info--navigation--wrapper{
    width: 200px;
}
.personal-info--navigation{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.personal-info--navigation>div{
    border-left: 4px solid transparent;
    margin: 5px 0;
    padding-left: 8px;
    cursor: pointer;
    font-size: 800;
}

.personal-info--navigation .active-personal-info--navigation{
    border-left: 4px solid #2B93A5;
    padding-left: 8px;
    color: #2B93A5;
}
.personal-info--navigation .unactive-personal-info--navigation{
    border-left: 4px solid transparent;
    padding-left: 8px;
    color: #94A2AB;
}

.personal-info--content--page{
    width: 100%;
}


.payments--title{
    width: 150px;
    padding: 30px 0 15px 5px;
    border-bottom: 1px solid #94A2AB;
}

.payment-cards--wrapper{
    border-bottom: 1px solid #94A2AB;
    margin: 15px 0;
    padding: 10px 0;
    display: flex;
}
.payment-cards--left--wrapper>div{
    margin: 2px;
}
.payment-cards--left--wrapper,.payment-cards--right--wrapper{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.payment-card-payment-name--wrapper{
    display: flex;
}
.payment-card-payment-name--left-border{
    width: 10px;
    border-radius: 5px;
    background-color: #2B93A5;
    box-shadow:0 0 3px #2B93A5;
    margin: 0 3px;
}
.payment-card-payment-name{
    color: #606060;
}
.payment-card-description{

}
.payment-card-due-date{
    font-size: 14px;
}
.payment-card-due-date img{
    margin: 0 3px;
}
.payment-cards--right--wrapper{

}
.payment-amount{
    font-size: large;
    font-weight: 500;
}
.payment-paynow-btn{
    width: 100px;
    padding: 3px;
    border-radius: 5px;
    background-color: #329C00;
    font-weight: 500;
    color: white;
    text-align: center;
    cursor: pointer;
}

.showRequest-Save-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

@media (max-width: 768px) {
    .pathway-requestBtn--wrapper{
        display: none;
    }
    .payment-cards--left--wrapper, .payment-cards--right--wrapper{
        width: auto;
    }
    .payment-cards--right--wrapper{
        text-align: center;
        padding: 0 3px;
        margin:0 auto;
    }
    .payment-cards--wrapper{
        border: 1px solid #94A2AB;
        border-radius: 8px;
    }
    .payment-cards--wrapper{
        padding: 5px 0;
        font-size: 14px;
    }
    .payments--title,.deal-heading--wrapper{
        display: none;
    }
    .payment-card-due-date{
        font-size: 12px;
    }
    .deal-info--container{
        border: 0;
        box-shadow: 0 0 0 transparent;
    }
    .assigned-tutors--container {
        margin: 0;
        width: auto;
    }
    .tutors--container--nav-wrapper{
        width: auto;
        margin-top:10px;

    }
    .showRequest--container{
        margin: 8px auto;
        width: 100%;
    }
    .dealsFlow-Status--container{
        overflow-x: scroll;
        height: 80px;
    }
    .dealFlow-lines-wrapper,.dealFlow-icons--wrapper{
        width: 520px;
    }
    .deal-mileStone--icon--wrapper{
        display: none;
    }
    .dealFlow-icons--wrapper{
        height: 20px;
        justify-content: space-between;
    }
    .dealFlow-lines-wrapper{
        height: 40px;
    }
    .deal-mileStone--wrapper{
        height: 80px;
    }
    .end-of-dealFlow-icons-line {
        display: none;
    }
    .small-input--wrapper,.dealFlow-solid-line{
        width: 99%;
    }
    .deal-heading--subheading{
        font-size: 15px;
    }
    .deal-info--container--title-wrapper {
        padding: 0;
        display: none;
    }
    .deal-info--container--title{
        width: 50%;
    }
    .deal-info--container--editOption{
        width: 50%;
    }
    .deal-mileStone--status--wrapper{
        width: 18px;
        height: 18px;
    }
    .dealFlow-solid-line {
        border: 1px solid #2B93A5
    }
    .deal-mileStone--title {
        border-radius: 5px;
        margin: 0px;
        padding: 2px;
    }
    .demo-tutors--container--nav-wrapper{
        width: 100%;
    }
    .demo-tutors--container--nav-wrapper>div {
        padding: 1px 12px;
        width: auto;
        font-weight: 600;
        font-size: 14px;
    }
    .demo-tutors--container{
        width: 100%;
    }
    .payment-pending--title,.payment-pending--payment-amount,.payment-pending--description{
        font-size: medium;
    }
    .dashboard-payment-popup .payment-pending--description{
        /* border: 1px solid black; */
        /* margin: 0 15px; */
        width: 80%;

    }
    .pending--process--steps--wrapper{
        font-size: 15px;
    }
    .showRequest--Edit-btn{
        width: auto;
    }
    .payment-pending--paynow-btn {
        width: 100px;
    }
    .small-input--wrapper input{
        width: 90%;
    }

    .formtype-select-options-wrapper{
        flex-direction: column;
        align-items: center;

    }
    .formtype-select-option-1, .formtype-select-option-2 {
        margin: 10px 0;
    }
    .tutor-hire--popup--wrapper{
        height: 420px;
    }
    .tutor-popup-wrapper--content-screen {
        width: 88%;
        height: auto;
    }
    .pending--payment--TC{
        text-align: start;
    }
    .pending--process-wrapper{
        padding: 20px;
    }
    .payment-pending--wrapper{
        height: 350px;
    }

}




















