.loginInputField {
    color: rgba(24, 24, 24, 0.49);
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    margin: 10px;
    width: 280px;
}

/* Primary button style */
.loginPrimaryButton {
    border: 0;
    width: 200px;
    height: 35px;
    border-radius: 32px;
    background: linear-gradient(180deg, #6EB3BE 0%, #3095A7 100%);
    color: #fff !important;
    margin: 10px;
    cursor: pointer;
    text-decoration: none;
}
.register-btns-wrapper button a{
    color: black ;
    /* width: 250px; */
    display: flex;
    justify-content: center;
    align-items: center;

}

.registerPrimaryButton{
    /* border: 1px solid black; */
    border-radius: 10px;
    background-color: white;
    color: black ;
    width: 200px;
    /* height: 35px; */
    /* display: flex; */
}

.warningAlert {
    background-color: #f44336;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    margin: 10px;
}

/* Error alert style */
.errorAlert {
    background-color: #ff8f8f;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    margin: 10px;
}
.login--wrapper{
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 50vh; */
}

.login-btns-wrapper{
    margin:20px 0;
    display: flex;
    flex-direction: column;
}
.register-btns-wrapper img {
    height: 43px;
}
.register-btns-wrapper{
    flex-direction: row;
    /* border: 1px solid black; */
}

.input-loading-wrapper{
    display: flex;
    align-items: center;
}
.genetating-otp-loader{
    /* display: inline; */
}
.genetating-otp-loader img,.login-btns-wrapper img{
    width: 25px;
}
.active-img{
    visibility: visible;
}
.inactive-img{
    visibility: hidden;
}
.login-btns-wrapper a{
    text-decoration: none;
    color: white;
}
.login-btns-wrapper div{
    display: flex;
    align-items: center;
    justify-content: center;
}


#whatsapp-login-btn{
    display: none;
}
@media (max-width: 768px) {
    #whatsapp-login-btn{
        display: block;
    }
    .loginInputField{
        width: 250px;
    }

}