@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,600&display=swap');
*{
  margin: 0%;
  padding: 0%;
  font-family: Open Sans;
  text-decoration: none;
}
input,select{
  background-color: #fff;
}
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
}
.pac-logo:after{
  display: none !important ;
}
.top-bar{
  border-bottom: 1px solid #E2E2E2;
  position: fixed;
  top: 0;
  left: 0;
  width: 92vw;
  z-index: 501;
  background-color: white;
  padding: 0 64px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo{
  border-radius: 50% 50% 0 0;
  overflow: hidden;
  
}

.top-bar-btns{
  padding: 6px 15px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  background-color: rgba(43, 147, 165, 1);
}


.navigation-wrapper{
  display: flex;
  margin-left: -300px;
}
.navigation-wrapper a{
  font-size: 16px;
  font-weight: 400;
  margin: 0 10px;
  text-decoration: none;
  cursor: pointer;
}
.unactive-link{
  color: #bebcbc;
}
.unactive-link:hover{
  color: #9e9d9d;
}
.active-link {
  color: black;
}
.top-bar--profile-wrapper{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.top-bar--profile-wrapper>div{
}
.main{
  padding: 0 64px;
  margin-top: 68px;
}
.greetings--wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
}
.greetings--wrapper--info-wrapper{
  display: flex;
}
.profilePic--wrapper{
  border-radius: 50%;
  margin-left: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.profile-page--wrapper{
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.profilePic--wrapper-2{
  height: 60px;
  width: 60px;
}
.greetings--user{
  font-weight: 500;
  color: black;
}
.user--overview{
  color: #bebcbc;
}
.greetings--info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  font-weight: 400;
  font-size: 15px;
}
.primary-btn{
  padding: 8px 10px;
  border-radius: 5px;
  background-color: #2B93A5;
  box-shadow: 0px 0px 3px #2B93A5;
  cursor: pointer;
  text-align: center;
  color: white;
  font-weight: 600;
}
.primary-btn a{
  color: white;
}
.secondary-btn{
  padding: 8px 10px;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  background-color: rgba(148, 162, 171, 1);
  box-shadow: 0px 0px 3px rgba(148, 162, 171, 1);
  text-align: center;
  cursor: pointer;

}
.primary-btn:hover{
  background-color: #39bad1;
  box-shadow: 0px 0px 3px #2B93A5;

}
.reviews{
  padding: 40px 0;
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}
.reviews>div{
  box-sizing: border-box;
  width: 175px;
  /* max-width: ; */
}
.small-section .reviews{
  justify-content: space-around;
  padding: 0;
}
.reviews>div:last-child{
  display: block;
}
.reviews div :first-child img{
  height: 40px;
}
.review-content{
  font-weight: 400;
  font-size: 12px;
}
.reviews .star-icon{
  height: 15px;
}
.main .main{
  padding: 0;
}
.request-deals--wrapper{

  padding-bottom: 4%;
  display: flex;
  justify-content: space-between;
}
.request-deals--wrapper section{
  width: 100%;
}
.request-deals--wrapper--left{
  max-height: 55vh;
  /* border: 1px solid black; */
  overflow: scroll;
  
}
.request-deals--wrapper--right{
}


.deal-request-card--wrapper{
  border-radius: 0px 16px 16px 0px;
  box-shadow: 0 0 3px #dbcaca;
  border: 1px solid #dbcaca;
  min-height: 90px;
  width: 90%;
  border-left: 15px solid;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 10px 10px 15px;
  cursor: pointer;
}
.deal-request-card--wrapper:hover{
  box-shadow: 0 0 10px #dbcaca;
}

.request-deals--wrapper--left--title{
  font-size: 22px;
  color: #bebcbc;
  margin-bottom: 20px;
}
.request-deals--wrapper--right--title{
  font-size: 22px;
  color: #bebcbc;
  margin:0 0 20px 20px;

}

.deal-request-card--top-part--left{
  display: flex;
  align-items: center;
}
.deal-request-card--top-part--left>img{
  margin-right: 10px;
}
.deal-request-card--top-part--right{
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}
.deal-request-card--light{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.deal-request-card--top-part{
  display: flex;
  justify-content: space-between;
  
}
.deal-request-card--mid-part{
  font-size: 18px;
  color: #bebcbc;
  
}
.deal-request-card--bottom-part{
  display: flex;
  justify-content: space-between;
  color: #bebcbc; 
}

.preWatch-video--wrapper{
  display: flex;
  justify-content: center;
  position: relative;
}
.preWatch-video--playicon-wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #2B93A5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.preWatch-video--playicon-wrapper:hover{
  background-color: #39bad1;

}

.ads-space--wrapper{
  padding-top: 20px;
  /* border: 1px solid black; */
  margin: 0px auto;
  height: 150px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  font-size: 20px;
  color: #555555;
}
.ads-space--wrapper .formtype-select-options-wrapper{
  width: 100%;
  padding: 0;
  margin: 0;
}






body::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

body::-webkit-scrollbar-track {
  background-color: white;
}

body::-webkit-scrollbar-thumb {
  background-color: #c4c8cc;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #979899;
}

.request-deals--wrapper--left::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.request-deals--wrapper--left::-webkit-scrollbar-track {
  background-color: white;
}

.request-deals--wrapper--left::-webkit-scrollbar-thumb {
  background-color: #c4c8cc;
  border-radius: 5px;
}

.request-deals--wrapper--left::-webkit-scrollbar-thumb:hover {
  background-color: #979899;
}

.need-tutor-add-wrapper{
  margin: 5px 0;
}

.mobile-navigation-wrapper{
  position: absolute;
  top: 55px;
  padding: 20px;
  padding-bottom: 30px;
  z-index: 500;
  background-color: white;
  width: 100vw;
  height: 55vh;
  box-sizing: border-box;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobile-navigation-wrapper{
}
.mobile-navigation-top-bar{
  display: flex;
  justify-content: space-between;
}

.mobile-navigation-content-wrapper{
  height: 160px;
}
.mobile-navigation-bottom-wrapper .primary-btn{
  margin-top: 25px;
  
}
.mobile-navigation-bottom-wrapper div{
  margin: 6px auto;
}

.greetings--wrapper--new-request-btn-mobile{
  display: none;
}


@media (max-width: 768px) {

  .ads-space--wrapper .formtype-select-options-wrapper{
    flex-direction: row;
  }
  .ads-space--wrapper .formtype-select-option-2,.ads-space--wrapper .formtype-select-option-1{
    width: 140px;
    font-weight: 400;
    font-size: 15px;
    /* height: 70px; */
  }
  .ads-space--wrapper .formtype-select-option-1 img,.ads-space--wrapper .formtype-select-option-2 img{
      width: 30px;
  }

  .request-deals--wrapper--left{
    max-height: 42vh;
  }
  .greetings--wrapper--info-wrapper,.greetings--info{
    width: 100%;
  }
  .profilePic--wrapper{
    margin-left: 0;
  }

  .greetings--wrapper--new-request-btn-mobile{
    display: flex;
    margin: 8px 0;
  }

  .greetings--wrapper--new-request-btn-desktop{
    display: none;
    margin-top: auto;
    font-size: 12px;
    width: 110px;
  }
  .request-deals--wrapper{
    flex-direction: column;
    justify-content: flex-start;
  }

  .request-deals--wrapper--right > div:first-child{
    display: none;
  }
  .reviews{
    width: 100%;
    justify-content: space-between;
    padding: 15px 0 ;
  }
  /* .small-section .reviews{
    justify-content: space-between;
  } */
  .reviews>div{
    min-width: 30%;
  }
  .reviews>div:last-child{
    display: none;
  }
  .review-content{
    font-weight: 400;
    font-size: 10px;
  }
  .reviews div :first-child img{
    height: 20px;
  }
  .top-bar,.main{
    padding: 0 15px;
  }
  .top-bar{
    height: 50px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .logo{
    font-size: 20px;
    
  }
  .deal-request-card--mid-part{
    font-size: 16px;
  }
  .primary-btn,.secondary-btn{
    padding: 3px;
    font-size: medium;
  }
  .App{
  }

  .navigation-wrapper{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background-color: white;
    width: 40%;
    height: 100%;
    margin-left:0px;
    padding: 10px;
  }
  .navigation-wrapper a{
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
  }
  .active-link {
    color: rgba(43, 147, 165, 1);
  }

  .greetings--wrapper{
    padding: 10px 0;
  }
  .deal-request-card--wrapper {
    min-height: 80px;
    width: 93%;
    box-shadow: 0 0 3px #dbcaca;
    border: 1px solid #dbcaca !important;
    padding: 8px ;
    margin-bottom: 10px;
    border-radius: 10px;

  }

  .request-deals--wrapper--left--title {
    font-size: 18px;
    color: rgba(130, 130, 130, 1);
    font-weight: 600;
    margin-bottom: 10px;
  }

  .deal-request-card--top-part--right{
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .deal-request-card--top-part--right >div:first-child{
    margin-left: 5px
  };

  


  

}