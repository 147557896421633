
.need-a-tutor .top-session {
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
}
button {
  cursor: pointer;
}

.need-a-tutor-top-session--leftpart-wrapper {
  width: 45%;
}
.need-a-tutor-biglogo--wrapper {
  /* border: 1.9px solid rgb(206, 205, 205); */
  /* border-radius: 50%; */
  width: 500px;
  height: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
}
.need-a-tutor-biglogo--title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  z-index: 10;
}
.need-a-tutor-biglogo--wrapper img {
  width: 360px;
  z-index: 0;
}
.need-a-tutor-top-session--rightpart-wrapper {
  width: 90%;
}
.need-a-tutor-top-session--rightpart--form-wrapper {
  width: 70%;
  margin: 0 auto;
}
.need-a-tutor-top-session--rightpart-wrapper .form-status--wrapper {
  height: 80px;
}
.steps-wrapper {
  display: flex;
  justify-content: space-evenly;
}
.step-status-wrapper {
  color: rgb(95, 95, 95);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.step-status-wrapper > div {
  cursor: pointer;
}
.step-status-circle {
  margin-top: 20px;
  border: 4px solid rgba(217, 217, 217, 1);
  background-color: rgb(243, 243, 243);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.blue-border {
  border: 4px solid rgba(43, 147, 165, 1);
}
.blue-bg {
  background-color: rgba(43, 147, 165, 1);
}
.line-wrapper-under-steps-wrapper {
  border-top: 3px solid rgba(217, 217, 217, 1);
  margin-top: -11px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.form--container .selection--wrapper {
  border: 2.5px solid rgba(43, 147, 165, 1);
  padding: 5px;
  margin: 5px 0;
  width: 100%;
  /* color: black; */
  color: #979899;
  border-radius: 5px;
  display: flex;
  z-index: 10;
  flex-wrap: wrap;
}
.selection--option-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 97%;
  /* height: 30px; */
  color: #979899;
}
.selection--option-wrapper label {
  z-index: 100;
}
.hideDropdown {
  display: none !important;
}
.showDropdown {
  display: flex;
}
.form--container .selection--option-wrapper input[type="radio"] {
  width: 15%;
  accent-color: rgba(43, 147, 165, 1);
}

.tc-dialog-box {
  border: 1px solid rgba(0, 0, 0, 0.774);
  box-shadow: 0 0 5px black;
  border-radius: 5px;
  position: absolute;
  width: 95%;
  margin: auto;
  top: 20px;
  padding: 10px;
  z-index: 10000;
}
.tc-dialog-box button {
  display: block;
  margin: 20px auto;

  padding: 10px 15px;
  width: 150px;
  background-color: #fff;
  font-weight: 600;
  border-radius: 5px;
}


.need-a-tutor-top-session--rightpart-wrapper .input-group {
  margin-bottom: 20px;
}

.need-a-tutor-top-session--rightpart-wrapper label {
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
  color: rgba(24, 24, 24, 0.79);
  /* margin-right: 3px; */
  padding-right: 8px;

  /* display: flex;
  flex-wrap: nowrap; */
  width: 99%;
}

.need-a-tutor-top-session--rightpart-wrapper input {
  height: 20px;
}

.need-a-tutor-top-session--rightpart-wrapper input,
.need-a-tutor-top-session--rightpart-wrapper select {
  color: rgba(24, 24, 24, 0.79);
  border-radius: 5px;
  border: 2px solid rgba(43, 147, 165, 1);
  margin: 5px 5px 0 0;
  /* background-color: #fff; */
  margin-bottom: 10px;
  font-family: "Open Sans";
  padding: 5px;
  font-size: 16px;
  width: 100%;
}
.need-a-tutor-top-session--rightpart-wrapper input[type="checkbox"] {
  width: auto;
  transform: scale(1.2);
  margin: 5px;
  accent-color: rgba(43, 147, 165, 1);
}
/* .need-a-tutor-top-session--rightpart-wrapper option{
  padding: 40px;
  font-size: 16px;
} */
.need-a-tutor-top-session--rightpart-wrapper select {
  width: 102%;
}
.need-a-tutor-top-session--rightpart-wrapper
  .form-buttons-wrapper
  .form--container {
  width: 80%;
}
.need-a-tutor-top-session--rightpart-wrapper .form-buttons-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}
.need-a-tutor-top-session--rightpart-wrapper .form-buttons-wrapper > button {
  width: 120px;
  border: 0;
}

.form_title {
  padding: 10px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: rgba(43, 147, 165, 1);
}

.certified-by-india-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0 0;
  margin: 20px 0 0 0;
}
.certified-by-india-section--title {
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.certified-by-india-section--midline {
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.32);
  margin: 20px 0;
}
.certified-by-india-section--imgs-wrapper {
  display: flex;
  width: 70%;
  justify-content: space-evenly;
}
.certified-by-india-section--imgs-wrapper img {
  height: 120px;
}

.small-section {
  margin: 50px 0 10px 0;
}
.small-blue-border {
  width: 11%;
  height: 7px;
  border-radius: 10px;
  background-color: rgba(43, 147, 165, 1);
  margin-bottom: 10px;
}
.small-section--text {
  display: flex;
}
.small-section--text-left--title {
  font-family: Open Sans;
  font-size: 25px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}

.small-section--text-left {
  width: 50%;
}
.small-section--text-right {
  width: 50%;
}

.how-it-works-parts {
  display: flex;
  width: 50%;
}

.how-it-works--wrapper {
  margin: 80px 0;
  display: flex;
}
.how-it-works-step--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 300px;
  width: 50%;
}
.how-it-works-step--image-wrapper {
  /* height: 45%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.how-it-works-step--content--container {
  padding-left: 30px;

  display: flex;
  flex-direction: column;
  justify-content: end;
  /* height: 50%; */
}
.how-it-works-step--image--container {
  position: relative;
  border: 10px solid rgba(43, 147, 165, 1);
  height: 135px;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.how-it-works-step--image--container img {
  width: 50%;
}
.how-it-works-step--image--container .how-it-works-step--curve--image {
  position: absolute;
  right: -130%;
  width: 160px !important;
}
.up-curve {
  top: -50%;
}
.down-curve {
  top: 50%;
}

.how-it-works-step--content--container .step-no {
  font-size: 34px;
  font-weight: 700;
}
.how-it-works-step--content--container .step-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
}
.how-it-works-step--content--container .step-desc {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
}
.second-main-pic-wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}
.second-main-pic {
  width: 450px;
}

.faq-row {
  border: 2px solid rgba(43, 147, 165, 1) !important;
  margin: 15px 0;
  border-radius: 5px;
  padding: 5px 15px !important;
}
.row-title {
  font-weight: 500;
}
.row-content {
  /* padding: 8px 0 0 0; */
}
.tutors-slider-cards {
  margin: 50px 0;
}

.tutors-slider-cards .slider-card {
  width: 300px;
  height: 250px;
  margin: 5px 10px;
  box-shadow: 0 0 4px rgba(117, 117, 117, 0.699);
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly; */
}
.slider-card .slider-card-image {
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  height: 155px;
  background-color: #fff;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
#main-slider-container {
  width: auto !important;
}
.slider-icon.left {
  left: auto !important;
  right: 0 !important;
}
.slider-icon.right {
  right: auto !important;
  left: 0 !important;
}
.customer-say--slider-cards {
  margin: 50px 0;

  overflow: scroll;
}
.customer-say-slider--wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 850%;
}
.customer-say-slider-card {
  width: 300px;
  /* height: 185px; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
  margin: 5px 10px;
  box-shadow: 0 0 4px rgba(117, 117, 117, 0.699);
}
.customer-say--slider-cards .slider-card-image {
  display: none;
}
.customer-say--slider-cards .slider-icon.left {
  display: none;
}
.customer-say--slider-cards .slider-icon.right {
  display: none;
}
.customer-say-slider-card--main-container {
  height: 70%;
}
.customer-say-slider-card-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.customer-say-slider-card--customer-name {
  font-size: 12px;
  font-weight: 700;
  line-height: 25px;
}
.customer-say-slider-card-desc {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  padding: 5px 0;
}
.customer-say-slider-card--small-container {
  display: flex;
  /* height: 15%; */
}
.customer-say-slider-card--img-wrapper {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer-say-slider-card--customer--info-wrapper {
  margin-left: 10px;
}

.customer-say--slider-cards::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.customer-say--slider-cards::-webkit-scrollbar-track {
  background-color: white;
}

.customer-say--slider-cards::-webkit-scrollbar-thumb {
  background-color: #c4c8cc;
  border-radius: 0px;
}

.customer-say--slider-cards::-webkit-scrollbar-thumb:hover {
  background-color: #979899;
}

.footer-section {
  margin-bottom: 500px;
}

@media (max-width: 768px) {
  .second-main-pic {
    width: 350px;
  }
  .second-main-pic-wrapper {
    width: 100%;
    height: 450px;
  }
  .how-it-works-step--curve--image {
    display: none;
  }
  .certified-by-india-section {
    padding: 0;
    margin: 0;
  }
  .certified-by-india-section--title {
    font-size: 15px;
    /* font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left; */
  }
  .certified-by-india-section--midline {
    /* width: 90%;
    border: 1px solid rgba(0, 0, 0, 0.32);
    margin: 20px 0; */
  }
  .certified-by-india-section--imgs-wrapper {
    flex-direction: column;
    align-items: center;
    width: 70%;
    /* display: flex;
    justify-content: space-evenly; */
  }
  .certified-by-india-section--imgs-wrapper img {
    height: 90px;
  }
  .small-section--text-left--title {
    line-height: 30px;
  }

  .selection--option-wrapper {
    max-width: 100%;
  }
  .form--container .selection--option-wrapper input[type="radio"] {
    width: 14px;
    accent-color: rgba(43, 147, 165, 1);
  }
  .need-a-tutor .top-session {
    flex-direction: column;
    margin-top: 50px;
  }
  .need-a-tutor-biglogo--wrapper {
    width: 210px;
    height: 275px;
  }
  .need-a-tutor-top-session--leftpart-wrapper,
  .need-a-tutor-top-session--rightpart-wrapper {
    width: auto;
  }
  .need-a-tutor-biglogo--title {
    font-size: 20px;
    font-weight: 700;
    line-height: 35px;
    margin-right: -0px;
  }
  .need-a-tutor-biglogo--wrapper img {
    width: 215px;
    margin-right: -0px;
  }
  .need-a-tutor-biglogo--wrapper img {
    /* margin-top: -45px; */
  }
  .need-a-tutor-top-session--rightpart--form-wrapper {
    width: 90%;
  }
  .steps-wrapper {
    justify-content: space-between;
  }
  .step-status-wrapper {
    margin-top: 10px;
  }
  .step-status-circle {
    margin-top: 10px;
  }
  .line-wrapper-under-steps-wrapper {
    width: 100%;
  }
  .need-a-tutor-top-session--rightpart-wrapper select {
    width: 104%;
  }
  .small-section--text {
    flex-direction: column;
  }
  .small-section--text-left {
    width: 100%;
  }
  .small-section--text-left > div:last-child {
    /* font-size: 13px; */
  }
  .small-section--text-right {
    font-size: 13px;
    width: 100%;
  }
  .how-it-works--wrapper {
    flex-direction: column;
  }
  .how-it-works-parts {
    width: 100%;
    margin: 10px 0;
  }
  .customer-say-slider--wrapper {
    width: 1700%;
  }
  .customer-say-slider-card {
    width: 260px;
  }
  .styles_faq-row-wrapper__3vA1D
    .styles_row-body__1NvUo
    .styles_faq-row__2YF3c
    .styles_row-title__1YiiY
    .styles_row-title-text__1MuhU {
    font-size: 0.8em;
    padding-right: 2.8em;
  }
  .how-it-works-step--wrapper {
    height: auto;
  }
  .how-it-works-step--image-wrapper {
    display: none;
  }
}
